import React from 'react'

import Title from '@components/title'
import Layout from '@components/layout'
import Button from '@components/button'

const NotFound = ({ uri }) => (
  <Layout currentLocation={uri}>
    <div className="tc">
      <Title heading="h1" textCenter className="mb6">
        Page not found
      </Title>
      <Button to="/" className="m-auto">
        Koti
      </Button>
    </div>
  </Layout>
)

export default NotFound
